import axios from "axios";
import { getToken } from "./auth";


export const baseURL = (() => {
  const { hostname } = window.location;

  if (hostname === "localhost") {
    return "http://localhost:8000"; // Local development API
  } else if (hostname === "sdbrokers.insignio.tech") {
    return "https://sd-backend.insignio.tech"; //  API
  } else if (hostname === "royalbrokers.insignio.tech") {
    return "https://royal-backend.insignio.tech"; // Production API
  } else if (hostname === "comcapital.insignio.tech") {
    return "https://comcapital-backend.insignio.tech"; // Production API
  } else if (hostname === "cms.insignio.tech") {
    return "https://cms-backend.insignio.tech"; // Production API
  }else if (hostname === "platform.insignio.tech") {
    return "https://platform-backend.insignio.tech"; // Production API
  }
  else {
    throw new Error("Unknown domain! Cannot set API baseURL.");
  }
})();


const API = axios.create({
  baseURL: baseURL + "/api",
});

API.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { API };
